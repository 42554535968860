<template>
	<div id="Explosion">
		<div class="Explosion">
			<div class="Title">
				<p>可证公平性技术保障</p>
				<div class="Title-lower"></div>
			</div>
			<div class="UnpackingRules-box-info">
				<p class="MsoNormal" ><span ><span >1.关于概率：盲盒内各等级饰品概率见盒内标示。本活动是为市场促销策划的福利回馈性质趣味性休闲娱乐活动，开盒存在概率性，请理性消费，切勿沉迷</span></span></p>
<p class="MsoNormal" ><span ><span >2.关于饰品：受游戏固有特性限制，相同款式&nbsp;CS:GO&nbsp;饰品外观上可能存在较大的视觉差异，我们仅保能保证饰品的磨损值在相应的区间，望知晓。</span></span></p>
<p class="MsoNormal" ><span ><span >3.关于提取：由于市场变化、系统问题、不可抗力等因素影响，饰品可能出现缺货情形，平台将通过各种方式提供解决方案（包括但不限于预采购、调货、换货），用户方在提取过程中主动拒绝收货将受到封禁惩罚，三次拒绝则永久不能提取。如因供应商发货超时、账号无法收货等问题导致提取失败，可联系在线客服解决。</span></span></p>
<p class="MsoNormal" ><span ><span >4.关于兑换：应法律法规要求，饰品不支持兑换为法定货币。&ldquo;皮肤盲盒&rdquo;福利活动饰品不支持七天无理由退货、不支持退补差价，望各位理解！</span></span></p>
<p class="MsoNormal" ><span ><span >5.特别提醒：为保障账户财产安全请尽快提取您的饰品，发起提取后饰品处于发货状态。</span></span></p>
<p class="MsoNormal" ><span ><span >6. 18岁以下未成年人禁止购买！</span></span></p>
<p class="MsoNormal"><span style="font-family: Calibri; font-size: 10.5pt;">&nbsp;</span></p>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Explosion',
		data() {
			return {

			}
		}
	}
</script>

<style lang="scss">
	.Explosion-box {
		width: 10rem;
		margin: 0 auto;
		padding-top: 0.4rem;

		.UnpackingRules-box-info {
			margin-top: 0.3rem;

			.title {
				font-size: 0.18rem;
				color: #73d171;
				height: 0.5rem;
				line-height: 0.5rem;
			}

			p {
				font-size: 0.16rem;
				color: #fff;
				line-height: 1.75em;
			}
		}
	}
</style>
